.ant-layout {
  background: none;
}

.ant-layout-sider {
  background: none;
}

.ant-upload.ant-upload-select-picture-card {
  width: 140px;
  height: 140px;
}

.ant-form-item-label {
  color: #595959;
  text-align: right;
  font-family: "Monospaced Number", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
}

.ant-input, .ant-select-selection-search-input, .ant-select-item-option-content, .ant-select-selection-item {
  color: #595959;
}

.tall-cell .ant-table-cell {
  height: 65px;
}

.thin-cell .ant-card-extra, .ant-card-head-title {
  padding: 0;
}

.thin-cell .ant-card-head {
  min-height: 40px;
  display: grid;
}

.clickable .ant-table-cell {
  cursor: pointer;
}

.ant-form legend {
  border: 0;
  padding: 0 1rem;
  white-space: normal;
  font-size: 14px;
  width: auto;
  margin: 0 1rem;
  color: #6d6d6d;
}

.ant-upload-disabled {
  cursor: default !important;
}

.App-Select-Readonly .ant-select-selection-search{
  display: none;
}

.App-Select-Readonly .anticon-close{
  display: none;
}

.ant-modal-body .ant-form-item {
  margin-bottom: 10px;
}

.thin-form .ant-form-item {
  margin-bottom: 10px;
}

.App-Campaign .ant-select-selection-overflow-item {
  max-width: 300px;
}

.ant-card-actions {
  background: #f5f5f5;
  border-color: #d9d9d9;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 9999;

}

.row-dragging td {
  padding: 8px 12px;
}

.row-dragging .ant-checkbox-wrapper {
  display: none;
}
